<template>
  <div>
    <Loader :loading="criteriaLoading" v-if="criteriaLoading" />
    <div v-if="!criteriaLoading">
      <Header>
        <template #route-back-component>
          <vue-feather type="chevron-left"
                       @click="$router.push({name:'AllCandidates',params:{id:$route.params.id}})"
                       style="color: #001343; cursor: pointer" />
        </template>
        <template #right-header-component>
          <profile-drop-down/>
        </template>
      </Header>
      <div class="tw-mt-20 applyFlexCenter">
        <div class="tw-w-full tw-m-5 lg:tw-w-4/5">
          <div class="back tw-hidden lg:tw-flex tw-items-center tw-cursor-pointer tw-w-20" @click="showConfirmModal" >
            <vue-feather type="chevron-left"  class="tw-mr-1" /> Back
          </div>
          <div class="tw-flex tw-w-full tw-flex-col-reverse lg:tw-flex-row">
            <div class="lg:tw-w-3/4 tw-flex tw-flex-col">
              <div class="tw-hidden lg:tw-flex lg:tw-flex-row lg:tw-justify-between tw-w-full  lg:tw-pr-20 lg:tw-mt-10">
                <div>
                  <p class=" card-header">Interview Evaluation Criteria</p>
                  <p class="card-subHeader tw-text-enumGrey2 ">Select from the criteria below. You can also add yours with <br/> the “+ Add Criteria” button.</p>
                </div>
                <base-button button-text="Save" :disabled="disableSave" @click="addInterviewCriteriaToJob" :loading="loading"/>
              </div>
              <div class="tw-flex card-criteria tw-flex-row tw-flex-wrap tw-justify-between lg:tw-mr-20 tw-mt-10  tw-items-center lg:tw-p-4 ">
                <div class="tw-flex tw-flex-row lg:tw-items-center card tw-p-2 lg:tw-p-0 tw-h-auto lg:tw-h-10 lg:tw-w-3/2">
                  <v-checkbox v-model="defaultUserCriteria" />
                  <p class="card-subHeader tw-text-enumGrey1 tw-mt-3">Make your current selection Default Evaluation Criteria for all Jobs</p>
                </div>
                <div class="tw-flex tw-justify-between tw-w-full lg:tw-w-auto tw-mt-10 lg:tw-mt-0">
                  <div class="tw-flex  lg:tw-mr-8">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="tw-items-start tw-text-enumDarkBlue card-subHeader"
                            text
                            v-bind="attrs"
                            v-on="on">
                          <vue-feather type="filter" class="tw-mr-2" style="width: 15px; height: 13.5px"/>
                          <label class="card-subHeader tw-text-enumDarkBlue">Sort by</label>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="sort in sortBy" :key="sort">
                          <v-list-item-title @click="handleSortBy(sort)"
                                             class="card-subHeader tw-cursor-pointer hover:tw-bg-enumGrey4">{{sort}}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <div class=" tw-flex card-subHeader tw-items-center tw-text-enumBlue tw-font-bold tw-cursor-pointer" @click="showModal">
                    <vue-feather type="plus"/> Add Criteria
                  </div>
                </div>
              </div>
                <div  class="tw-flex tw-flex-row tw-flex-wrap tw-w-full scroll tw-justify-between tw-pr-3 lg:tw-pr-20 tw-mt-10 tw-mb-5">
                  <criteria-card v-for="(criteria) in allCriteria"
                                 :key="criteria.id" :title="criteria.title" :desc="criteria.description"
                                 :id="criteria.id"
                                 :created-by-user="criteria.is_owner"
                                 :class="[selectedCriteria.includes(criteria) ? 'card-criteria-selected' : 'card-criteria-select']"
                                 class="tw-mb-10 tw-cursor-pointer" :selected="selectedCriteria.includes(criteria)"
                                 @handleSelectedCriteria="handleSelectedCriteria(criteria,true)"
                                 @showDeleteModal="showConfirmModal"
                                 @handleEditSelectedCriteria="handleEditSelectedCriteria"/>
                </div>

            </div>
            <div class="tw-w-full lg:tw-w-1/4 lg:tw-p-5">
              <p class="lg:tw-hidden card-header">Interview Evaluation Criteria</p>
              <p class="lg:tw-hidden card-subHeader tw-text-enumGrey2">Select from the list of Criteria below, you can also yours with the “+ Add Criteria” button.</p>
              <div class="card tw-p-4 tw-w-full tw-flex tw-flex-col">
                <p class="card-midTitle">job details</p>
                <p class="card-Title">{{job_role}}</p>
                <p class="card-subHeader tw-flex-row tw-text-enumGrey2 lg:tw-pb-10">Created on {{new Date(created_date).toDateString()}} by {{created_by}}</p>
              </div>
              <div class="card tw-p-4 tw-w-full tw-flex tw-flex-col tw-mt-10">
                <p class="card-midTitle">rating guide </p>
                <p class="rating">Rate candidate during the interview based on selected criteria.</p>
                <div class="tw-flex-col">
                  <div class="tw-flex tw-flex-row tw-items-center" v-for="rating in ratings" :key="rating.figure" >
                    <p class="rating-figure tw-mr-3">{{rating.figure}}</p>
                    <p class="rating-text">{{rating.text}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <add-item-modal :dialog="dialog" @showModal="showModal" :title="modalTitle"
                      @closeModal="closeModal" @getAllCriteria="getAllCriteria" />
      <splash-confirm-modal :dialog="confirmDialog" :title="confirmTitle"
                            :sub-title="confirmSubTitle"
                            @close="closeDeleteModal" @function="handleCriteriaOption"/>

    </div>
    <v-app-bar bottom absolute fixed color="white" style="height: max-content" class="lg:tw-hidden">
      <base-button button-text="Save" width="100%"
                   class=" tw-flex tw-w-full tw-mt-3 tw-mb-3" :disabled="disableSave" :loading="loading" @click="addInterviewCriteriaToJob" />
    </v-app-bar>
  </div>
</template>

<script>
import Header from "../../components/onboarding/reuseables/Header";
import ProfileDropDown from "../../components/dashboard/reuseables/ProfileDropDown";
import BaseButton from "../../components/UIComponents/BaseButton";
import AddItemModal from "../../components/dashboard/reuseables/AddItemModal";
import CriteriaCard from "../../components/dashboard/reuseables/CriteriaCard";
import SplashConfirmModal from "../../components/UIComponents/SplashConfirmModal";
import {addInterviewCriteriaToJob, deleteCriteria, getAllCriteria, getJobById} from "../../services/api/APIService";
import Loader from "../../components/UIComponents/reusablesIcon/Loader";

export default {
  name: "EvaluationCriteria",
  components: {
    Loader, SplashConfirmModal, CriteriaCard, AddItemModal, BaseButton, ProfileDropDown, Header},
  data(){
    return{
      job_role: "Senior Designer",
      modalTitle : "Add Criteria",
      created_by : "Created on August 2nd, 2021 by Great Ndabai ",
      created_date : "",
      sortBy:["My Criteria", "Default Criteria"],
      criteriaLoading : false,
      loading : false,
      ratings:[
          {
            figure : "5",
            text : "Excellent"
          },
        {
          figure: "4",
          text: "Very Good"
        },
        {
          figure: "3",
          text: "Good"
        },
        {
          figure: "2",
          text: "Poor"
        },
        {
          figure: "1",
          text: "Very Poor"
        }
      ],
      dialog : false,
      allCriteria :[],
      selectedCriteria : [],
      confirmDialog : false,
      confirmTitle : "",
      confirmSubTitle : "",
      saveChange : false,
      selectedCriteriaId : 0,
      criteriaToDeleteId : null,
      criteriaToType : null,
      defaultUserCriteria : false,
      disableSave: true


    }
  },
  methods:{
    showModal() {
      this.modalTitle = "Add Criteria"
      this.dialog = !this.dialog;

    },
    handleSelectedCriteria(criteria,click){
      this.handleSaveChanges(click)
      if (this.selectedCriteria.includes(criteria)) {
        this.selectedCriteria = this.selectedCriteria.filter(s => s !== criteria)
      }else this.selectedCriteria.push(criteria)
    },
    closeModal(){
      this.dialog = false
    },
    showConfirmModal(id, type){

      if (type === 'Delete'){
        this.confirmDialog = true
        this.confirmTitle = "Delete Criteria"
        this.confirmSubTitle = "Are you sure you want to delete this criteria?"
        this.criteriaToDeleteId = id
        this.criteriaType = type

      }
      else {
        if (this.saveChange === true) {
          this.confirmDialog = true
          this.confirmTitle = "Are you sure you want to go back?"
          this.confirmSubTitle = "All current Selection will be lost!"
        }
        else {
          this.$router.push({name : 'AllCandidates',params:{id:this.$route.params.id}})
        }
      }

    },
    closeDeleteModal(){
      this.confirmDialog = false
    },
    handleCriteriaOption(){
      if (this.criteriaType === 'Delete'){
        this.deleteCriteria()
      }
      else {
        this.$router.push({name : 'AllCandidates',params:{id:this.$route.params.id}})
      }
    },
    handleEditSelectedCriteria(criteriaId){
      this.modalTitle = "Edit Criteria"
      sessionStorage.setItem('editCriteriaId',criteriaId)
      this.dialog = true
    },
   async getAllCriteria(){
      this.criteriaLoading = true
      this.handleSaveChanges(false)
     let data ={}
     data.id = this.$route.params.id
     data.site_name = this.$siteName()
      getAllCriteria(data).then(async res =>{
        this.allCriteria = res.data
      await getJobById(data).then(job_res =>{
        this.defaultUserCriteria = job_res.data.user_criteria
        this.created_by = job_res.data.created_by
        this.job_role = job_res.data.role
        this.created_date = job_res.data.date_created
        this.allCriteria.map((criteria)=>{
          job_res.data.interview_criteria.map((criteriaId)=>{
            if (Number.parseInt(criteriaId) === criteria.id){
              this.handleSelectedCriteria(criteria)
            }
          })
        })
      })
           .catch(err =>{
             console.log(err.response.data.detail)
             this.criteriaLoading = false
             this.$displaySnackbar({message:err.response.data.detail, success:false})
           })
       this.criteriaLoading = false
      })
          .catch(err =>{
            console.log(err.response.data.detail)
            this.criteriaLoading = false
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    },
    deleteCriteria(){
      if (this.criteriaToDeleteId){
        let data = {}
        data.id = this.criteriaToDeleteId
        data.site_name = this.$siteName()
        deleteCriteria(data).then(res =>{
          console.log(res.data)
          this.confirmDialog = false
          this.$displaySnackbar({message:"Interview Criteria Deleted successfully", success:true})
          this.getAllCriteria()
        })
            .catch(err =>{
              console.log(err.response.data.detail)
              this.criteriaLoading = false
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
      }
    },
    addInterviewCriteriaToJob(){
      this.loading = true
      const data = {};
      data.job_id = this.$route.params.id
      data.site_name = this.$siteName()
      data.user_criteria = this.defaultUserCriteria
      let criteriaIds = [];
      this.selectedCriteria.map((criteria)=>{
        criteriaIds.push(criteria.id)
      })
      data.criteria_id = criteriaIds
      addInterviewCriteriaToJob(data).then(res => {
        this.handleSaveChanges(false)
        this.disableSave = true
        console.log(res.data.id,this.saveChange)
        this.getAllCriteria()
        this.$displaySnackbar({message:"Selected Interview Criteria was updated successfully", success:true})
        this.loading = false
        if(this.$route.query.email){
          this.$router.push({name:'ConductInterviewView',query:this.$route.query})
        }

      })
          .catch(err =>{
            console.log(err.response.data.detail)
            this.loading = false
            this.criteriaLoading = false
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    },
    handleSortBy(type){
      console.log(type)
      if (type === "My Criteria"){
         this.allCriteria.sort((a,b) => !a.is_owner - !b.is_owner)
      }
      else {
        this.allCriteria.sort((a,b) => a.is_owner - b.is_owner)
      }

    },
    handleSaveChanges(click){
      if (click){
        this.disableSave = false
      }
      this.saveChange = click
    }
  },
  created() {
    this.disableSave = true
    this.getAllCriteria()
  }
}
</script>

<style scoped lang="scss">
.back{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: #001343;
  text-transform: none;
}
.card{
  background: #FAFAFA;
  border-radius: 8px;
}

.card-criteria{
  @media screen and (min-width: 1240px){
    background: #FAFAFA;
    border-radius: 8px
  }
}

.card-midTitle{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #001343;
}
.card-Title{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #001343;
}
.card-header{
  font-family: IBM Plex Serif, serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}
.card-subHeader{
  font-family: DM Sans, serif !important;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: none;
}
.rating{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  align-items: center;
  letter-spacing: 0.01em;
  color: #001343;
}
.rating-figure{
  font-family: IBM Plex !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #000000;
}
.rating-text{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #000000;
}

.card-criteria-select{
  border: 1px solid #9CABB5;
  box-sizing: border-box;
  border-radius: 16px;
}
.card-criteria-select:hover{
  border: 1px solid #008EEF;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.24);
  border-radius: 16px;
}
.card-criteria-selected{
  border: 1px solid #008EEF;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.24);
  border-radius: 16px;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
</style>
