<template>
<div class="card-width tw-p-5 tw-flex-col" @click="$emit('handleSelectedCriteria')">
  <div class="tw-flex-row tw-w-full tw-flex tw-justify-between tw-items-center">
    <div v-if="!selected" class="title tw-text-enumDarkBlue">{{title}}</div>
    <div v-if="selected" class="title tw-text-enumBlue">{{title}}</div>
    <div class="tw-flex tw-items-center">
      <v-img contain v-if="selected" src="../reuseables/check-circle.svg" />
      <v-menu offset-y v-if="createdByUser">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="tw-text-enumDarkBlue tw-items-center card-subHeader tw-font-bold"
              text
              v-bind="attrs"
              v-on="on">
            <vue-feather type="more-vertical" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="option in options" :key="option">
            <v-list-item-title class="tw-cursor-pointer hover:tw-bg-enumGrey4"
                               @click="handleSelectedOption(option,id)">{{option}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
  <hr class="hr tw-mt-3"/>
  <p class="desc tw-mt-3">{{desc}}</p>
</div>
</template>

<script>
export default {
  name: "CriteriaCard",
  props:{
    title : [String],
    desc : [String],
    createdByUser : Boolean,
    selected : Boolean,
    id: Number
  },
  data(){
    return{
      options: ["Edit","Delete"]
    }
  },
  methods:{
    handleSelectedOption(type, optionId){
      if (type === 'Edit'){
        this.$emit('handleEditSelectedCriteria',optionId)
      }
      else if (type === 'Delete'){
        this.$emit('showDeleteModal', optionId, type)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card-width{
  width: 100%;
  @media screen and (min-width: 1240px){
    width: 48%;
  }
}
.title{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
}
.desc{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  color: #001343;
}
.hr{
  border: 1px solid #D0DCE4
}
</style>