<template>
<div>
  <slot name="dialogBtn">

  </slot>
  <v-dialog
      v-model="dialog"
      max-width="600"
      transition="scale-transition"
      origin="center center"
      persistent>
    <v-card class="pa-8" style="border-radius: 8px;" >
      <div class="tw-flex tw-justify-between">
        <v-card-title class="card-Title">
          {{ title }}
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <ValidationObserver v-slot="{handleSubmit}">
        <form @submit.prevent="handleSubmit(handleCreateInterviewCriteria)">
          <ValidationProvider
          mode="passive"
          name="criteriaTitle"
          rules="required"
          v-slot="{ errors }">
            <div class="tw-flex tw-flex-col">
              <small class="label tw-text-enumGrey1">Title</small>
              <v-text-field class="field-title" solo  placeholder="Enter Criteria Title" v-model="criteriaTitle" id="criteriaTitle" />
              <span v-show="errors" class="err">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
              mode="passive"
              name="Description"
              rules="required"
              v-slot="{ errors }">
            <div class="tw-flex tw-flex-col ">
              <small class="label tw-text-enumGrey1">Description</small>
              <v-textarea class="field-title" solo  placeholder="Enter Criteria Description" v-model="description"  id="description" />
              <span v-show="errors" class="err">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="tw-flex tw-flex-row  tw-pb-10 tw-mt-8">
            <base-button button-text="Cancel" outlined class="tw-mr-5" type="button" @click="closeModal"/>
            <base-button button-text="Save" type="submit" :loading="loading" />
          </div>
        </form>
      </ValidationObserver>
    </v-card>

  </v-dialog>
</div>
</template>

<script>
import BaseButton from "../../UIComponents/BaseButton";
import {createInterviewCriteria, getCriteriaById, updateCriteriaById} from "../../../services/api/APIService";
export default {
  name: "AddItemModal",
  components: {BaseButton},
  props:{
    title : [String],
    dialog : Boolean,
  },

  data(){
    return{
      loading : false,
      criteriaTitle : "",
      description : "",
    }
  },
  watch:{
    title : function (val){
      if (val === "Edit Criteria"){
        const criteriaId = sessionStorage.getItem('editCriteriaId')
        this.populate(criteriaId)
      }else {
        this.criteriaTitle = ""
        this.description = ""
        sessionStorage.removeItem('editCriteriaId')
      }
    }
  },

  methods:{
  async handleCreateInterviewCriteria(){
    this.loading = true
    let criteriaData = {};
    criteriaData.title = this.criteriaTitle
    criteriaData.description = this.description
    criteriaData.site_name = this.$siteName()
    const criteriaId = sessionStorage.getItem('editCriteriaId')
    if (criteriaId){
      criteriaData.id = criteriaId
      await  updateCriteriaById(criteriaData).then(res =>{
        console.log(res.data)
        sessionStorage.removeItem('editCriteriaId')
        this.loading = false
        this.$displaySnackbar({message: 'Evaluation Criteria updated successfully', success: true})
        this.clearData()
        this.$emit('getAllCriteria')
        this.$emit('showModal')
      })
          .catch(err => {
            console.log(err.response.data.detail)
            this.loading = false
            this.$displaySnackbar({message: err.response.data.detail, success: false})
          })
    }else {
      await createInterviewCriteria(criteriaData).then(res => {
        console.log(res.data)
        this.loading = false
        this.$displaySnackbar({message: 'Evaluation Criteria added successfully', success: true})
        this.clearData()
        this.$emit('getAllCriteria')
        this.$emit('showModal')
      })
          .catch(err => {
            console.log(err.response.data.detail)
            this.loading = false
            this.$displaySnackbar({message: err.response.data.detail, success: false})
          })
    }
  },
    populate(id){
      if (id){
        let data ={};
        data.id = id
        data.job_id = this.$route.params.id
        data.site_name = this.$siteName()
        getCriteriaById(data).then(res =>{
          this.criteriaTitle = res.data.title
          this.description = res.data.description

        })
            .catch(err =>{
              console.log(err.response.data.detail)
              this.loading = false
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
      }

    },
    clearData() {
      this.description = ""
      this.criteriaTitle = ""
    },
    closeModal(){
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped>
.card-Title{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #001343;
}

.field-title{
  font-family: DM Sans !important;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  color: #031245;
}
.label{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.err{
  color: red;
  font-size: 14px;
  margin-top: -1rem;
}
</style>